
import React, { useContext, useEffect, useState } from 'react';
import Flatlist from 'flatlist-react';
import axios from 'axios';

// STYLE LINKS
import styles from '../css/screens/menu.module.css';

// IMPORT ICONS
import { IoCloseCircleSharp } from 'react-icons/io5';

// FROM ANOTHER ARCHIVES
import Loader from '../components/loader';
import { formatValue } from '../scripts/formatValue';
import CompanyContext from '../contexts/companyContext';
import ProductContext from '../contexts/productContext';
import ScreenContext from '../contexts/screenContext';
import ClientDataContext from '../contexts/clientContext';
import BoxAlert from '../components/boxAlert';
import { ServerPath } from '../classes/serverPath';
import LoadingContext from '../contexts/loadingContext';
import CatalogContext from '../contexts/catalogContext';

const no_image_vector = require('../assets/marca-dagua-sofchef.png');

function Menu() {


    // COMPANY DATA CONTROL
    const { companyData } = useContext(CompanyContext);

    const { setScreen } = useContext(ScreenContext);

    const { setProductData } = useContext(ProductContext);

    // CLIENT DATA CONTROL
    const { clientData } = useContext(ClientDataContext);

    // MENU SCREEN DATA
    const {catalog, setCatalog} = useContext(CatalogContext);

    const [combos, setCombos] = useState([]);

    const [lastOrderedProducts, setLastOrderedProducts] = useState([]);

    const [category, setCategory] = useState(null);


    const [scrolledScreen, setScrolledScreen] = useState(false);


    // LOADING CONTROL

    const [loading, setLoading] = useState(true);




    //  INITIALIZE SCREEN
    useEffect(() => {
        try {
            setLoading(true);

            (async () => {
                await getCatalog();

                if(clientData?.client_id){
                    await getLastOrderedProducts(clientData?.client_id);
                }
            })();

        } catch (error) {
            console.error('ERROR - CONSTRUCT SCREEN FUNCTION:', error);

        } finally {
            setLoading(false);
        }
    }, []);




    // GET CATALOG FUNCTION
    const getCatalog = async () => {
        try {
            let response = await axios.get(`${ServerPath}/smartmenu/get-catalog`, {
                params: {
                    companyId: companyData.id,
                },
            });

            console.warn(response);

            if (response.status == 200) {

                if(response.data.combos.length > 0){
                    setCombos(response.data.combos.sort((a, b) => a.position - b.position));
                }

                // CONTROLA OS DADOS A SEREM INSERIDOS NO CATALOGO
                const data = [];

                for (const cat of response.data?.categories) {

                    const groupProducts = response.data?.products.filter((product) => {
                        const isValid = (product.restricted && !clientData) || (product.restricted && clientData && clientData.older) || (product.restricted && !companyData.restriction) || !product.restricted;

                        if (product.category === cat.id && product.view && isValid) {
                            return product;
                        }
                    });

                    if(groupProducts.length > 0){
                        const gp = groupProducts.sort((a, b) => a.position - b.position);
    
                        data.push({ ...cat, products: gp });

                    }
                }

                console.log(data);

                setCatalog(data);
            }
            
        } catch (error) {
            console.error('ERROR - GET CATEGORIES FUNCTION:', error);
        }
    };



    const getLastOrderedProducts = async (id) => {
        try{

            const res = await axios.get(`${ServerPath}/smartmenu/get-client-last-ordered-products`, {
                params:{
                    company_id: companyData.id,
                    client_id: id
                }
            })

            console.log('getLastOrderedProducts', res.data)

            if(res.status == 200){
                setLastOrderedProducts(res.data);
            }

        } catch (error) {
            console.error('ERROR - GET CLIENT LAST PRODUCTS FUNCTION:', error);
        }
    }


    // CHANGE THE FIRST CATEGORY TO RENDER BASED IN YOUR POSITION VALUE
    useEffect(() => {
        if(catalog.length > 0){
            setCategory(catalog[0].name);
        }
    }, [catalog]);


    // RENDER CATEGORIES FUNCTION ================================>
    const renderCategories = (item, index) => {

        return (
            item.products.length > 0 && (
                <li key={index}>
                    <a href={`#${item.name}`}>
                        <button id={`cat${index}`} className={styles.btnCategory} onClick={() => setCategory(item.name)}>
                            <p style={{ color: category == item.name ? companyData.background_color : '#b3b3b3', fontWeight: 600 }}>{item.name}</p>
                        </button>
                    </a>
                </li> 
            )
        );
    };


    // FUNÇÃO DE RENDERIZAÇÃO DO CATÁLOGO DO MENU
    const renderCatalog = (item, index) => {

        return (
            <li key={index}>
                <div id={item.name}>
                    <h1 className={styles.titleCategoriesProdList}>{item.name}</h1>

                    {item.products.map((prod, index) => {
                        

                        // FORMAT PRICE VALUES ==========>
                        const price = formatValue(prod.price);

                        const promotionPrice = prod.promotion && prod.promotion_price ? formatValue(prod.promotion_price) : null;

                        // CONTROLS THE PRODUCTS WHO WILL NOT BE SHOW TO CLIENTS UNDER 18 YEARS
                        if ((companyData.restriction && prod.restricted && clientData && !clientData.older) || !prod.view) {
                            return;

                        } else {
                            return (
                                <li key={index}>
                                    <button
                                        className={styles.productComponent}
                                        style={{ backgroundColor: prod.active == false || (prod.useStock == true && prod.stockQuantity == 0) ? '#999' : '#fff' }}
                                        onClick={() => {
                                            if (!companyData.status || !prod.active) {
                                                return;

                                            } else {
                                                let arr = prod;
                                                setProductData(arr);
                                                setScreen('product');
                                            }

                                        }}>

                                        <img className={styles.imgProductMenu} src={prod.image ? `${ServerPath}/archives/images?dir=products&file=${prod.image}` : no_image_vector} />

                                        <div className={styles.productsTextArea}>
                                            <p className={styles.textProducts} id={styles.productName} wrap="hard">
                                                {prod.name}
                                            </p>

                                            {/* ITEM DESCRIPTION CAN BE A NULL VALUE */}
                                            {prod.description && (
                                                <p className={styles.textProducts} id={styles.productDescription} style={{ color: '#555' }}>
                                                    {prod.description}
                                                </p>
                                            )}

                                            {prod.promotion == true ? (
                                                <div className={styles.textProducts} id={styles.productPrice} style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p id={styles.oldPrice} style={{ color: 'red', textDecoration: 'line-through', marginRight: 10 }}>
                                                        {price}
                                                    </p>
                                                    {/* <span id={styles.separator-promotion-price' style={{ marginLeft: 5, marginRight: 5, }}>por</span> */}
                                                    <p id={styles.newPrice} style={{ color: 'green' }}>
                                                        {promotionPrice}
                                                    </p>
                                                </div>
                                            ) : (
                                                <p className={styles.textProducts} id={styles.productPrice}>
                                                    {item.use_complements && 'A partir de'} {price}
                                                </p>
                                            )}
                                        </div>
                                    </button>
                                </li>
                            );
                        }
                    })}
                </div>
            </li>
        );
    };



    const renderLastOrderedProducts = (item, index) => {

        // FORMAT PRICE VALUES ==========>
        const price = formatValue(item.price);

        const promotionPrice = item.promotion && item.promotion_price ? formatValue(item.promotion_price) : null;


        return(
            <button className={styles.comboComponent} onClick={() => {
                    if (!companyData.status || !item.active) {
                        return;

                    } else {
                        let arr = item;
                        arr.type = 'combo';
                        setProductData(arr);
                        setScreen('product');
                    }

                }}>

                <div className={styles.imgComboMenu} style={{backgroundImage: item.image ? `url(${ServerPath}/archives/images?dir=products&file=${item.image})` : `url(${no_image_vector})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} />

                <div className={styles.informationsComboArea}>
                    <div className={styles.comboContainer}>
                        <p className={styles.textProducts} id={item.name} style={{whiteSpace: 'wrap'}}>{item.name}</p>

                        {item.description && <p className={styles.textProducts} id={styles.productDescription} style={{ color: '#555' }}>{item.description}</p>}

                        <p className={styles.textProducts} id={styles.productPrice}>A partir de {price}</p>

                        {item.end_date && <p className={styles.textProducts} id={styles.productDescription} style={{marginTop: 10}}>Expira em: {item.end_date}</p>}

                    </div>
                </div>
            </button>
        )
    }


    
    // const renderCombos = (item, index) => {

    //     // FORMAT PRICE VALUES ==========>
    //     const price = formatValue(item.price);

    //     const promotionPrice = item.promotion && item.promotion_price ? formatValue(item.promotion_price) : null;


    //     return(
    //         <button className={styles.comboComponent} onClick={() => {
    //                 if (!companyData.status || !item.active) {
    //                     return;

    //                 } else {
    //                     let arr = item;
    //                     arr.type = 'combo';
    //                     setProductData(arr);
    //                     setScreen('product');
    //                 }

    //             }}>

    //             <div className={styles.imgComboMenu} style={{backgroundImage: item.image ? `url(${ServerPath}/archives/images?dir=products&file=${item.image})` : `url(${no_image_vector})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} />

    //             <div className={styles.informationsComboArea}>
    //                 <div className={styles.comboContainer}>
    //                     <p className={styles.textProducts} id={item.name} style={{whiteSpace: 'wrap'}}>{item.name}</p>

    //                     {item.description && <p className={styles.textProducts} id={styles.productDescription} style={{ color: '#555' }}>{item.description}</p>}

    //                     <p className={styles.textProducts} id={styles.productPrice}>A partir de {price}</p>

    //                     {item.end_date && <p className={styles.textProducts} id={styles.productDescription} style={{marginTop: 10}}>Expira em: {item.end_date}</p>}

    //                 </div>
    //             </div>
    //         </button>
    //     )
    // }



    // CONTROLA O EVENTO DE SCROLL PARA MODIFICAR O POSICIONAMENTO DA HEADER
    document.addEventListener('scroll', () => {
        const scrollY = window.scrollY;

        const spacing = 130;
        
        if (scrollY > spacing) {
            setScrolledScreen(true);

        } else {
            setScrolledScreen(false);   
        }

    });






    // SCREEN ===================================================================>

    return (
        <div className={styles.screen}>

            {/* <======================= HEADER =======================> */}
            <header className={styles.headerMenu}>
                {companyData.show_service || companyData.show_address || companyData.show_comercial_phone || companyData.show_comercial_email ? (
                    <button className={styles.buttonProfile} style={{backgroundColor: companyData.background_color}} onClick={() => setScreen('about')}>
                        <p className={styles.buttonProfileText} style={{color: companyData.text_color}}>Perfil da loja</p>
                    </button>
                ) : null}

                <div className={styles.companyIdentityArea}>
                    {companyData.use_image && companyData.image && <img src={`${ServerPath}/archives/images?dir=logos&file=${companyData.image}`} className={styles.headerCompanyLogo} alt={'Logo'} />}

                    <div className={styles.companyIdentityTextArea}>
                        {/* COMPANY NAME IN HEADER */}
                        <h1 className={styles.nameCompany}>{companyData.name}</h1>

                        <p className={styles.textStatusAlert} style={{ color: '#fff', padding: 5, borderRadius: 5, backgroundColor: companyData.status ? 'green' : 'red' }}>
                            {companyData.status ? 'Aberto' : 'Fechado'}
                        </p>
                    </div>
                </div>



                {/* <======================= CATEGORIES =======================> */}
                {!scrolledScreen && (

                    <div className={styles.categoriesArea} id='categories-area'>
                        <div className={styles.categories} id='categories' style={{ backgroundColor: companyData.background_color }}>
                            {catalog.length > 0 ? (
                                <div id={styles.scrollComponent} className={styles.scrollComponent}>
                                    <div id={styles.componentList} className={styles.componentList}>
                                        <Flatlist list={catalog} renderItem={renderCategories} sortBy="position" />
                                    </div>
                                </div>
                            ) : (
                                <p style={{ color: companyData.text_color, textAlign: 'center', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto' }}>Suas categorias de produtos ficarão aqui!</p>
                            )}
                        </div>
                    </div>
                    
                )}

            </header>


            <div id={styles.container}>

                {scrolledScreen && (
                    <div className={styles.fixedCategoriesArea}>
                        <div className={styles.categories} style={{ backgroundColor: companyData.background_color }}>
                            {catalog.length > 0 ? (
                                <div id={styles.scrollComponent} className={styles.scrollComponent}>
                                    <div id={styles.componentList} className={styles.componentList}>
                                        <Flatlist list={catalog} renderItem={renderCategories} sortBy="position" />
                                    </div>
                                </div>
                            ) : (
                                <p style={{ color: companyData.text_color, textAlign: 'center', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto' }}>Suas categorias de produtos ficarão aqui!</p>
                            )}
                        </div>
                    </div>
                )}


                {/* <======================= AREA DE PRODUTOS PEDIDOS ANTERIORMENTE =======================> */}
                {lastOrderedProducts.length > 0 && (
                    <section id={styles.combosArea}>
                        <p className={styles.titleCombosArea}>Pedir novamente</p>

                        <div id={styles.scrollComponent} className={styles.scrollComponent} style={{marginTop: 20, paddingBottom: 5}}>
                            <div id={styles.componentList} className={styles.componentList}>
                                <Flatlist list={lastOrderedProducts} renderItem={renderLastOrderedProducts} />
                            </div>
                        </div>
                    </section>

                )}



                {/* <======================= PRODUCTS AREA =======================> */}
                <section id={styles.productsArea}>
                    {catalog.length > 0 ? (
                        <div className={styles.productsList}>
                            <Flatlist list={catalog} renderItem={renderCatalog} sortBy="position" />
                        </div>
                    ) : (
                        <p style={{ width: '80%', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: 100, backgroundColor: '#d3d3d3', padding: 10, borderRadius: 5 }}>Adicione novos produtos a conta e eles irão aparecer aqui!</p>
                    )}
                </section>

            </div>
        </div>
    );
}

export default Menu;
