import { useContext } from "react"


// STYLE LINKS
import '../css/components/returnButton.css';


// ICONS
import { BiChevronLeft } from 'react-icons/bi';


// CONTEXTS
import ScreenContext from "../contexts/screenContext"
import CompanyContext from "../contexts/companyContext";




export default function ReturnButton (props) {

    const {companyData} = useContext(CompanyContext);

    const {setScreen} = useContext(ScreenContext);



    return (
        <button className="return-button" style={{ backgroundColor: companyData.text_color, paddingRight: props.showText ? 10 : 5 }} onClick={() => setScreen(props.screen || 'menu')}>
            <BiChevronLeft size={24} color={companyData.background_color} />
            {props.showText && (
                <span style={{ color: companyData.background_color }}>Voltar</span>
            )}
        </button>
    )
}