import { useContext, useEffect, useState } from 'react';
import '../css/components/bottomMenu.css';

// ICONS
import { SlNotebook } from 'react-icons/sl';
import { BsFillBagFill } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { AiOutlineHome } from "react-icons/ai";
import { IoCartOutline } from "react-icons/io5";


// CONTEXTS
import CompanyContext from '../contexts/companyContext';
import ScreenContext from '../contexts/screenContext';


export default function BottomNavigator({ changeScreen }) {
  
  const { companyData } = useContext(CompanyContext);
  
  const {screen, setScreen} = useContext(ScreenContext);

  const [countProducts, setCountProducts] = useState(0);



  useEffect(() => {
    findProducts();

    window.addEventListener('storage', findProducts);

    return () => {
      window.removeEventListener('storage', findProducts);
    };
  }, []);


  function findProducts() {
    let res = JSON.parse(localStorage.getItem('cart'));

    if (res && res.companyId !== companyData.id) {
      localStorage.clear('cart');
    }

    let soma = 0;

    if (res) {
      soma = res.products.reduce((soma, item) => soma + item.amount, 0);
    }

    setCountProducts(soma);
  }


  useEffect(() => {
    setTimeout(findProducts(), 1000);
  }, [countProducts, screen]);


  return (
    <section className="bottom-menu" style={{ backgroundColor: companyData.background_color }}>
      <button className="iconBtn" onClick={() => setScreen('menu')} style={{opacity: screen != 'menu' ? 0.6 : 1 }}>
        <AiOutlineHome color={companyData.text_color} size={25} />
        <p className={'text-bottom-navigator'} style={{color: companyData.text_color}}>Início</p>
      </button>

      <button className="iconBtn" onClick={() => setScreen('cart')} style={{opacity: screen != 'cart' ? 0.6 : 1 }}>
        <IoCartOutline color={companyData.text_color} size={30}/>
        {countProducts > 0 && (
          <p className="count-products" style={{ backgroundColor: companyData.text_color, color: companyData.background_color }}>
            {countProducts}
          </p>
        )}
        <p className={'text-bottom-navigator'} style={{color: companyData.text_color}}>Pedido</p>
      </button>

      <button className="iconBtn" onClick={() => setScreen('account')} style={{opacity: screen != 'account' ? 0.6 : 1 }}>
        <IoReceiptOutline color={companyData.text_color} size={25} />
        <p className={'text-bottom-navigator'} style={{color: companyData.text_color}}>Conta</p>

      </button>
    </section>
  );
}
