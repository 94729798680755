import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

// PAGES ===========================>
import SmartMenu from './cardapio/smartmenu';
import NotFound from './cardapio/notFound';


// CONTEXTS ===========================>
import { CompanyContextProvider } from './contexts/companyContext';
import { ProductContextProvider } from './contexts/productContext';
import { CatalogContextProvider } from './contexts/catalogContext';
import { ScreenContextProvider } from './contexts/screenContext';
import { LocationContextProvider } from './contexts/locationContext';
import { ClientDataContextProvider } from './contexts/clientContext';
import { LoadingContextProvider } from './contexts/loadingContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <LoadingContextProvider>
        <CompanyContextProvider>
            <ClientDataContextProvider>
                <LocationContextProvider>
                    <ScreenContextProvider>
                        <CatalogContextProvider>
                            <ProductContextProvider>
                                <BrowserRouter>
                                    <Routes>
                                        {/* <=================== ROTAS PARA CARDÁPIO ===================> */}

                                        <Route path="/cardapio/:companyParams/*" index element={<SmartMenu />} />

                                        {/* ============================================================> */}

                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </BrowserRouter>
                            </ProductContextProvider>
                        </CatalogContextProvider>
                    </ScreenContextProvider>
                </LocationContextProvider>
            </ClientDataContextProvider>
        </CompanyContextProvider>
    </LoadingContextProvider>
);

reportWebVitals();
