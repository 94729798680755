import React, {useState, useEffect, useContext, useRef} from 'react';
import CompanyContext from '../contexts/companyContext';


// STYLE
import styles from '../css/screens/about.module.css';


// ICONS
import { FaMapMarkerAlt } from "react-icons/fa";


// COMPONENTS
import Loader from '../components/loader';
import ReturnButton from '../components/returnButton';



export default function About(){


    const {companyData} = useContext(CompanyContext);

    const [loading, setLoading] = useState(false);

    const mapsLink = useRef(null);

    useEffect(() => {
        console.log('cpd in about', companyData)
        const address = `${companyData.street}, ${companyData.number}, ${companyData.state}, ${companyData.cep}`;

        mapsLink.current = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;

        console.log(mapsLink.current);
    }, [])


    return(
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>


                    {/* <======================= HEADER =======================> */}
                    <header className={styles.headerAbout} style={{ backgroundColor: companyData.background_color }}>
                        <ReturnButton background={companyData.text_color} color={companyData.background_color}/>
                        <h1 className={styles.titleAbout} style={{ color: companyData.text_color }}>Sobre nós</h1>
                    </header>


                    <div className={styles.container}>

                        {companyData.show_service && (
                            <section className={styles.informationSection}>
                                <p className={styles.titleSection}>Horário de atendimento</p>

                                <p className={styles.textSection} style={{lineHeight: 1.8}} dangerouslySetInnerHTML={{ __html: companyData.service_information.replace(/\n/g, "<br />") }}/>
                            </section>

                        )}

                        {(companyData.show_comercial_phone || companyData.show_comercial_email) && (
                            <section className={styles.informationSection}>
                                <p className={styles.titleSection}>Contato</p>

                                {companyData.show_comercial_phone && (
                                    <p className={styles.textSection}>Telefone: {companyData.phone}</p>
                                )}

                                {companyData.show_comercial_email && (
                                    <p className={styles.textSection}>Email: {companyData.email}</p>
                                )}

                            </section>

                        )}


                        {companyData.show_address && (
                            <section className={styles.informationSection}>
                                <p className={styles.titleSection}>Endereço</p>

                                <p className={styles.textSection}>Rua {companyData.street}, {companyData.number} - {companyData.district}, {companyData.city} - {companyData.state}, {companyData.cep} -  Brasil</p>

                                <a href={mapsLink.current || null} className={styles.mapsButton} style={{backgroundColor: companyData.background_color}}>
                                    <FaMapMarkerAlt size={20} color={companyData.text_color}/>
                                    <p className={styles.mapsButtonText} style={{color: companyData.text_color, marginLeft: 15}}>Abrir no mapa</p>
                                </a>
                            </section>
                        )}

                    </div>
                </div>
            )}

        </div>
    )
}