/*  PROPS CONTROLLER:

    - show
    - title
    - text
    - showInput
    - inputLabel
    - showButtons
    - confirmFunction
    - confirmButtonText
    - cancelFunction
    - cancelButtonText
    - onClose
*/

import { useContext } from 'react';

// STYLE LINKS
import styles from '../css/components/boxAlert.module.css';

// IMPORT ICONS
import { IoMdCloseCircle } from 'react-icons/io';

// FROM ANOTHER ARCHIVES
import CompanyContext from '../contexts/companyContext';

export default function BoxAlert(props) {
    const { companyData } = useContext(CompanyContext);

    return (
        <>
            {props.show ? (
                <div className={styles.alertComponent}>
                    <div className={styles.overlayCart}></div>

                    <div className={styles.alertBox}>
                        {props.showCloseButton ? (
                            <button className={styles.closeDataRequest} onClick={() => props.onClose()}>
                                <IoMdCloseCircle size={25} color="#333" />
                            </button>
                        ) : null}

                        <h1 className={styles.titleAlertBox} style={{ marginBottom: 0 }}>
                            {props.title}
                        </h1>

                        {props.text && <p className={styles.textAlertBox} style={{paddingBottom: props.input ? 0 : 60}}>{props.text}</p>}

                        {props.input && (
                            <div className={styles.inputRequestArea}>
                                {props.inputLabel && <p className={styles.labelRequestData}>{props.inputLabel}</p>}
                                {props.input}
                            </div>
                        )}

                        {!props.showButtons ? (
                            <button className={styles.proceedRequestDataBtn} style={{ backgroundColor: companyData.background_color }} onClick={() => props.confirmFunction ? props.confirmFunction() : props.onClose()}>
                                <p className={styles.textAlertButton} style={{ color: companyData.text_color }}>
                                    {props.confirmButtonText || 'Ok'}
                                </p>
                            </button>

                        ) : (
                            <div className={styles.buttonsBoxArea}>
                                <button className={styles.buttonBoxConfig} id={styles.leftBoxButton} style={{ backgroundColor: companyData.background_color, borderColor: companyData.text_color }} onClick={() => props.cancelFunction()}>
                                    <p className={styles.textAlertButton} style={{ color: companyData.text_color }}>
                                        {props.cancelButtonText || 'Voltar'}
                                    </p>
                                </button>
                                <button className={styles.buttonBoxConfig} id={styles.rightBoxButton} style={{ backgroundColor: companyData.background_color, borderColor: companyData.text_color }} onClick={() => props.confirmFunction()}>
                                    <p className={styles.textAlertButton} style={{ color: companyData.text_color }}>
                                        {props.confirmButtonText || 'Prosseguir'}
                                    </p>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
}
