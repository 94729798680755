
import React, { useContext, useEffect, useState } from 'react';
import { Route, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


// STYLES ==================================>
import '../css/components/scrollbar.css'; 

// SCREENS =========================================>
import Loader from '../components/loader';
import Menu from './menu';
import Cart from './cart';
import Account from './account';
import Product from './product';
import Register from './register';
import About from './about';

// FROM ANOTHER ARCHIVES ===================================>
import BottomNavigator from '../components/bottomNavigator';
import CompanyContext from '../contexts/companyContext';
import ScreenContext from '../contexts/screenContext';
import { AlertMessage } from '../components/alertMessage';
import LocationContext from '../contexts/locationContext';
import ClientDataContext from '../contexts/clientContext';
import { ServerPath } from '../classes/serverPath';
import LoadingContext from '../contexts/loadingContext';



function SmartMenu() {
  const { screen } = useContext(ScreenContext);

  const router = useNavigate();

  const [rendering, setRendering] = useState(false);

  const { companyParams } = useParams();

  // COMPANY DATA CONTROL
  const { companyData, setCompanyData } = useContext(CompanyContext);
  
  const {location, setLocation} = useContext(LocationContext);

  // const textColor = companyData.text_color === '#fff' || companyData.text_color === '#ffff' ? 'black' : companyData.text_color;

  // CLIENT DATA CONTROL
  const {clientData, setClientData} = useContext(ClientDataContext);

  const [showBottom, setShowBottom] = useState(false);

  // LOADING CONTROL
  const {loading, setLoading} = useContext(LoadingContext);


  // ERROR MESSAGE SCREEN CONTROL
  const [errorAlert, setErrorAlert] = useState(false);
  const [reservedTableAlert, setReservedTableAlert] = useState(false);



  useEffect(() => {
    (async () => {
      try {

        if(clientData.length === 0){
          const resClientData = localStorage.getItem('CLIENT_DATA');
  
          if(!resClientData || resClientData === 'undefined'){
            localStorage.removeItem('CLIENT_DATA');
  
          } else {
            setClientData(JSON.parse(resClientData) || null);
          }
        }

        // CONTROLE DE DATA STORAGE
        let storageLocation = parseInt(sessionStorage.getItem('table'));

        if (!companyParams) {
          throw new Error('NOT FOUND PARAMETERS');
        }

        let data = await getCompanyData(companyParams);
        
        if(!data.location){
          data.company.type = 'view';
        }

        // ANALISA DIFERENÇAS ENTRE OS DADOS DE LOCALIZAÇÃO ATUAL E OS SALVOS NO CACHE
        const current_location = data.location;
        
        // console.log('DATA:', data);

        if (data.company.type !== 'view' && (!storageLocation || current_location !== location || current_location !== storageLocation)) {
          await tableController(data.company.id, current_location);
        }

        // MODIFICA O TITULO DA PÁGINA WEB PARA O NOME DA EMPRESA
        if(data.company.name){
          document.title = `${data.company.name}`;
        }

        setCompanyData(data.company);
        
        setRendering(true);

      } catch (error) {
        console.error('ERROR - INITIALIZE SMART MENU:', error);
        setErrorAlert(true);

      } finally {
        setLoading(false);
      }
    })();
  }, []);



  useEffect(() => {
    if(rendering){
      setShowBottom(screen !== 'register' && screen !== 'reserved' && screen !== 'product' && screen !== 'about' ? true : false);
    }
  }, [screen, rendering]);



  // GET COMPANY DATA FUNCTION ===========================>
  const getCompanyData = async (params) => {
    try {

      const response = await axios.get(`${ServerPath}/smartmenu/get-company-data`, {
        params: {
          params: params,
        },
      });

      if (response.status === 200 && response.data) {
        // console.log('RESPONSE DATA AQUI!', response.data);
        return response.data;
      }

    } catch (error) {
      console.error('ERROR IN GET COMPANY DATA', error);
      if(error.response.status == 401 && error.response.data === "UNAUTHORIZED COMPANY"){
        router('/Error')
      }
      throw error;
    }
  };




  // SAVE DATA IN LOCAL STORAGE FUNCTION =============================>
  const tableController = async (companyId, moment_table) => {
    try {

      if(moment_table != 0){
        const response = await axios.get(`${ServerPath}/smartmenu/validate-table-client`, {
          params: {
            companyId: companyId,
            table: moment_table,
            clientData: clientData || null,
          },
        });
  
        if (response.status === 200) {
          // GET DATA FROM THE CURRENT LOCATION

          if (response.data.reserved) {
            setReservedTableAlert(true);
          }
  
        }

      }

      //  MODIFICA OS DADOS DE LOCAL DO CLIENTE

      if (clientData.length > 0) {
        let array = { ...clientData };
        array.location = moment_table;

        // console.log('SALVANDO DADOS DO CLIENTE: (smartmenu)', array);

        setClientData(array);

        localStorage.setItem('CLIENT_DATA', JSON.stringify(array));
      }

      setLocation(moment_table);

    } catch (error) {
      // O ALERTA PARA O CASO DE NÃO ENCONTRAR OS DADOS DA MESA É UM 404 COM 'NOT FOUND' COMO MENSAGEM
      // console.log('ERROR - TABLE VALIDATION:', error);
      throw error;
    }
  };






  // SCREEN =======================================================================>
  return (
    <div>
      {loading ? (
        <Loader/>
        
      ) : errorAlert ? (
        <AlertMessage MODE="text" TITLE="Sentimos muito" TEXT={`Parece que tivemos um problema em carregar as informações do estabelecimento!\n\nTente recarregar a página, ler novamente o QR-Code ou recorra a um colaborador para te auxiliar!`} />

      ) : reservedTableAlert ? (
        <AlertMessage MODE="text" TITLE="Mesa reservada" TEXT={`A mesa em questão está reservada. Caso seja você o cliente no qual realizou a reserva, recorra a um(a) colaborador(a) para que ele(a) possa liberá-la para uso!`} />

      ) : (

        <div>
          {screen === 'menu' ? <Menu /> : screen === 'cart' ? <Cart /> : screen === 'account' ? <Account /> : screen === 'product' ? <Product /> : screen === 'register' ? <Register/> : screen === 'about' ? <About/> : null }

          {/* RENDERIZA SOMENTE NAS TELAS PRINCIPAIS */}
          {showBottom && companyData.status && companyData.type === 'sales' && <BottomNavigator />}
        </div>
      )}
    </div>
  );
}

export default SmartMenu;
